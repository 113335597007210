<template>
    <div>
        <b-modal
            id="modal-create-invoice"
            no-close-on-backdrop
            size="lg"
            :title="i18nT(`Add finance record from transaction`)"
        >

            <b-card no-body class="py-1 px-3">
                <b-row class="">
                    <b-col cols="12" class="text-center my-2 border-bottom">
                        <h4>{{i18nT(`Transaction details`)}}</h4>
                    </b-col>
                    <b-col cols="12">
                        <div class="d-flex">
                            <div class="transaction-detail mr-2">
                                <strong>{{i18nT(`Amount`)}}</strong>
                                <p :class="transaction.Amount >= 0 ? `text-success` : `text-danger`">{{ {amount: transaction.Amount, currency: transaction.Currency} | currency }}
                                </p>
                            </div>
                            <div class="transaction-detail mr-2">
                                <strong>{{i18nT(`Date`)}}</strong>
                                <p>
                                    {{transaction.TransactionDate | date}}
                                </p>
                            </div>
                            <div v-if="transaction.BankAccountObj" class="transaction-detail mr-2">
                                <strong>{{i18nT(`Bank`)}}</strong>
                                <p>
                                    {{transaction.BankAccountObj.Bank}}
                                </p>
                            </div>
                            <div v-if="transaction.BankAccountObj" class="transaction-detail mr-2">
                                <strong>{{i18nT(`Bank Account`)}}</strong>
                                <p class="mb-0">
                                    {{transaction.BankAccountObj.Label}}
                                </p>
                                <p class="font-small-3 text-muted">
                                    {{transaction.BankAccountObj.Iban}}
                                </p>
                            </div>

                            <div class="transaction-detail mr-2">
                                <strong>{{i18nT(`Transaction ID`)}}</strong>
                                <p class="text-truncate max-w-200">
                                    {{transaction.BankTransactionId}}
                                </p>
                            </div>



                        </div>
                        <div class="d-flex">
                            <div v-if="transaction.DebtorName" class="transaction-detail mr-2">
                                <strong>{{i18nT(`Debtor Name`)}}</strong>
                                <p>
                                    {{transaction.DebtorName}}
                                </p>
                            </div>
                            <div v-if="transaction.CreditorName" class="transaction-detail mr-2">
                                <strong>{{i18nT(`Creditor Name`)}}</strong>
                                <p>
                                    {{transaction.CreditorName}}
                                </p>
                            </div>
                            <div class="transaction-detail">
                                <strong>{{i18nT(`Description`)}}</strong>
                                <p>
                                    {{transaction.Label}}
                                </p>
                            </div>
                        </div>

                    </b-col>
                </b-row>
            </b-card>


            <validation-observer ref="createInvoice">
                <b-col cols="12" class="text-center my-2 border-bottom">
                    <h4 v-if="documentType == 1">{{i18nT(`Income record details`)}}</h4>
                    <h4 v-if="documentType == 2">{{i18nT(`Expense record details`)}}</h4>
                </b-col>
                <b-row>
                    <b-col cols="6">
                        <b-form-group
                            :label="i18nT(`Category`)"
                            label-for="Category"
                            class="required"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="i18nT(`Category`)"
                                rules="required"
                            >
                                <b-form-select
                                    v-model="paymentCategory"
                                    :options="paymentCategoryList"
                                    label="label"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" >
                        <b-form-group
                            :label="documentType == 1 ? i18nT(`Customer`) : i18nT(`Supplier`)"
                            label-for="Vendor"
                            class="required"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="i18nT(`Vendor`)"
                                rules="required"
                            >
                                <b-form-select
                                    class="corners-straight-right input-with-semi-button"
                                    v-model="vendorId"
                                    :options="vendorList"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                <div class="semi-button cursor-pointer d-inline-flex mt-0 position-absolute top"
                                     v-b-tooltip="i18nT(`Add new vendor`)"
                                     v-b-modal.add-vendor
                                >
                                    <font-awesome-icon
                                        class="d-block text-white ml-2"
                                        :icon="['fa', 'plus']"></font-awesome-icon>
                                </div>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            :label="i18nT(`Document number`)"
                            label-for="Number"
                            class="required"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="i18nT(`Document number`)"
                                rules="required"
                            >
                                <b-form-input
                                    type="text"
                                    v-model="invoiceNumber"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col
                        cols="6"
                    >
                        <b-form-group
                            :label="i18nT(`VAT (%)`)"
                            label-for="VatPercent"
                        >
                            <b-form-input
                                v-model="vatPercent"
                                type="number"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </validation-observer>


            <!-- Modal Footer -->
            <template #modal-footer>
                <div class="d-flex justify-content-end">
                    <b-button
                        variant="outline-secondary"
                        class="mr-2"
                        right
                        @click="cancel"
                    >
                        {{i18nT(`Cancel`)}}
                    </b-button>

                    <b-button
                        variant="primary"
                        class="mr-2"
                        right
                        @click="createInvoice(false)"
                    >
                        {{i18nT(`Save`)}}
                    </b-button>
                    <b-button
                        variant="primary"
                        right
                        @click="createInvoice(true)"
                    >
                        {{i18nT(`Save and Edit`)}}
                    </b-button>
                </div>
            </template>
        </b-modal>
        <b-modal id="add-vendor"
                :title="i18nT(`Add new vendor`)"
                 size="xl"
                 hide-footer
                 centered
                 :no-close-on-backdrop="true"
                 :no-close-on-esc="true"
        >
            <edit-vendor @vendorAdded="onVendorAdded" :modalMode="true" />
        </b-modal>
    </div>


</template>

<script>

import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BButton,
    BModal,
    VBTooltip,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {dictToSelectArray} from "@core/utils/utils";
import useAuth from "@/auth/useAuth";
import EditVendor from "@/views/accounting/Vendors/EditVendor.vue";

export default {
    name: "TransactionToFinance",
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormTextarea,
        BButton,
        BModal,
        ValidationProvider,
        ValidationObserver,
        ToastificationContent,
        EditVendor
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    props: {
        transaction: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            vendorList: [],
            vendor: {},
            vendorId: null,

            paymentCategoryList: [],
            paymentCategory: 0,
            paymentCategoryId: null,

            vatPercent: 0,
            invoiceNumber: '',
            lastInvoiceNumber: '',

            saveAndEdit: false,

            required,
            email,
        }
    },
    computed: {
        documentType() {
            return this.transaction.Amount > 0 ? 1 : 2;
        },
    },
    methods: {
        onVendorAdded() {
            this.$bvModal.hide('add-vendor');
            this.$http.get(
                `vendors/vendorsList`
            ).then(({data}) => {
                this.vendorList = dictToSelectArray(data.data)
                this.vendorId = this.vendorList[this.vendorList.length - 1].value;
            });
        },
        cancel() {
            this.resetForm();
            this.$refs.createInvoice.reset();
            this.$bvModal.hide('modal-create-invoice')
        },
        resetForm() {
            this.vendorId = null;
            this.paymentCategory = 0;
            this.paymentCategoryId = null;
            this.vatPercent = 0;
            this.invoiceNumber = '';
        },
        createInvoice(saveAndEdit) {
            this.$refs.createInvoice.validate().then(success => {
                if (success) {

                    let formData = new FormData();
                    formData.append('transaction_id', this.transaction.Id);
                    formData.append('VendorId', this.vendorId);
                    formData.append('Category', this.paymentCategory);
                    formData.append('VatPercent', this.vatPercent);
                    formData.append('InvoiceNumber', this.invoiceNumber);

                    this.$http.post(`transactions/createInvoice`, formData).then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.successMessage(response),
                                icon: 'CheckIcon',
                                variant: 'success',
                                message: this.i18nT(`Record created successfully! Redirecting..`)
                            },
                        });
                        this.resetForm();
                        this.$refs.createInvoice.reset();
                        this.$bvModal.hide('modal-create-invoice')

                        if (saveAndEdit) {
                            if(this.documentType == 1)
                                if(this.paymentCategory.fullData.Type == 1){
                                    this.$router.push({name: 'edit-invoice', params: {id: response.data.data.Id}});
                                }else {
                                    this.$router.push({name: 'edit-income', params: {id: response.data.data.Id}});
                                }
                            else if(this.documentType == 2) {
                                this.$router.push({name: 'edit-expense', params: {id: response.data.data.Id}});
                            }
                        }

                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.errorMessage(error),
                                icon: 'XIcon',
                                variant: 'danger',
                                message: this.i18nT(`Something went wrong!`)
                            },
                        });
                    })

                } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.i18nT(`Please fill all required fields`),
                            icon: 'XIcon',
                            variant: 'danger',
                            message: this.i18nT(`Please fill all required fields`)
                        },
                    });
                }
            })
        },
        fetchCategories(docType) {
            this.$http.get(
                `invoices/categories?type=${docType}`
            ).then(({data}) => {

                this.paymentCategoryList = data.data.map(category => {
                    return {
                        value: category.Id,
                        text: category.Label,
                        fullData: category
                    }
                })
                if (docType == 1) {
                    let selectedCategory = this.paymentCategoryList.find(category => category.fullData.IsSystem == 1);

                    if (selectedCategory && selectedCategory.fullData) {
                        this.paymentCategory = selectedCategory.fullData.Id
                    }
                }
            })
        },
    },
    created() {
        this.$http.get(
            `vendors/vendorsList`
        ).then(({data}) => {
            this.vendorList = dictToSelectArray(data.data)
        })

        this.$http.get(
            `invoices/lastInvoiceNumber`
        ).then(({data}) => {
            this.lastInvoiceNumber = data.data
        })
        this.fetchCategories(this.documentType);

        let curWorkspace = useAuth.getCurWorkspace();
        this.vatPercent = curWorkspace.MainVatPercent;
    },
    watch: {
        documentType(newVal) {
            this.fetchCategories(newVal);
        },
    }
}
</script>

<style scoped lang="scss">
.max-w-200 {
    max-width: 200px;
}
.plain-modal {
    background-color: var(--light-gray);
}

.discountIcon {
    position: relative;
    margin: 0 auto;
    margin-top: calc(50% - 1px);
}

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}
</style>
