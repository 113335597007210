<template>
    <div>
        <b-modal
            id="modal-link-transaction"
            no-close-on-backdrop
            size="xl"
            :title="i18nT(`Record payment from transaction`)"
        >
            <!-- TRANSACTION DETAILS -->

            <b-row class="">
                <!-- FILTERS -->
                <b-col cols="6" >
                    <b-row>

                        <!-- FILTERS -->
                        <b-col
                            cols="12"
                            md="12"
                        >
                            <h4 v-if="documentType == 1" class="mb-2">{{i18nT(`Select income document`)}}</h4>
                            <h4 v-if="documentType == 2" class="mb-2">{{i18nT(`Select expense document`)}}</h4>
                            <b-form-group>
                                <div
                                    role="group"
                                    class="input-group input-group-merge"
                                >
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <feather-icon icon="SearchIcon"/>
                                        </div>
                                    </div>
                                    <b-form-input
                                        v-model.lazy="searchQuery"
                                        :placeholder="i18nT(`Search by document number`)"
                                        type="text"
                                        class="d-inline-block"
                                    />
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Vendor`)"
                                label-for="assignee"
                            >
                                <v-select
                                    id="assignee"
                                    v-model="vendor"
                                    :options="vendorList"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Category`)"
                                label-for="documentGroup"
                            >
                                <v-select
                                    id="documentGroup"
                                    v-model="category"
                                    :options="paymentCategoryList"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                                :label="i18nT(`Document from period`)"
                                label-for="paymentType"
                            >
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-button
                                            variant="primary"
                                            @click.stop="inputClick"
                                        >
                                            <font-awesome-icon  :icon="['fas', 'calendar-alt']" />
                                        </b-button>
                                    </b-input-group-prepend>
                                    <VueCtkDateTimePicker
                                        :id="'InvoiceDateRange'"
                                        class=""
                                        :range="true"
                                        :min-date="minDate"
                                        :disabled="false"
                                        v-model="reportRange"
                                        :only-date="true"
                                        :only-time="false"
                                        :format="`YYYY-MM-DD`"
                                        :formatted="dateFormat"
                                        :color="`#5a5aff`"
                                        :label="i18nT(`Select date`)"
                                        :button-now-translation="i18nT(`Today`)"
                                        :first-day-of-week="1"
                                        :no-button="true"
                                        :auto-close="true"
                                        :locale="localeCtk"
                                        :ref="`invoiceRangePicker`"
                                    />
                                </b-input-group>

                            </b-form-group>
                        </b-col>

                    </b-row>


                </b-col>

                <!-- TRANSACTION DETAILS -->
                <b-col cols="6">
                    <b-card no-body class="py-1 px-2">
                        <div class="text-center my-1">
                            <h4>{{i18nT(`Transaction details`)}}</h4>
                        </div>

                        <div class="d-flex">
                            <div class="transaction-detail mr-2">
                                <strong>{{i18nT(`Amount`)}}</strong>
                                <p :class="transaction.Amount >= 0 ? `text-success` : `text-danger`">{{ {amount: transaction.Amount, currency: transaction.Currency} | currency }}
                                </p>
                            </div>
                            <div class="transaction-detail mr-2">
                                <strong>{{i18nT(`Date`)}}</strong>
                                <p>
                                    {{transaction.TransactionDate | date}}
                                </p>
                            </div>
                            <div v-if="transaction.BankAccountObj" class="transaction-detail mr-2">
                                <strong>{{i18nT(`Bank`)}}</strong>
                                <p>
                                    {{transaction.BankAccountObj.Bank}}
                                </p>
                            </div>
                            <div v-if="transaction.BankAccountObj" class="transaction-detail mr-2">
                                <strong>{{i18nT(`Bank Account`)}}</strong>
                                <p class="mb-0">
                                    {{transaction.BankAccountObj.Label}}
                                </p>
                                <p class="font-small-3 text-muted">
                                    {{transaction.BankAccountObj.Iban}}
                                </p>
                            </div>

                            <div class="transaction-detail mr-2" v-if="false">
                                <strong>{{i18nT(`Transaction ID`)}}</strong>
                                <p class="text-truncate max-w-200">
                                    {{transaction.BankTransactionId}}
                                </p>
                            </div>



                        </div>
                        <div class="d-flex">
                            <div v-if="transaction.DebtorName" class="transaction-detail mr-2">
                                <strong>{{i18nT(`Debtor Name`)}}</strong>
                                <p>
                                    {{transaction.DebtorName}}
                                </p>
                            </div>
                            <div v-if="transaction.CreditorName" class="transaction-detail mr-2">
                                <strong>{{i18nT(`Creditor Name`)}}</strong>
                                <p>
                                    {{transaction.CreditorName}}
                                </p>
                            </div>
                            <div class="transaction-detail">
                                <strong>{{i18nT(`Description`)}}</strong>
                                <p>
                                    {{transaction.Label}}
                                </p>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>

            <validation-observer ref="createInvoice">

                <!-- Invoice list -->
                <b-row>
                    <b-col cols="6">
                        <b-table
                            ref="refDocumentListTable"
                            id="invoiceListTable"
                            class="position-relative mt-3"
                            :items="fetchDocuments"
                            responsive
                            :fields="tableColumns"
                            primary-key="id"
                            :sort-by.sync="sortBy"
                            show-empty
                            :empty-html="emptyHtml"
                            :sort-desc.sync="isSortDirDesc"
                            @refreshed="onTableRefresh"
                            @row-clicked="onRowClicked"
                            :tbody-tr-class="rowClass"
                            v-model="currentItems"
                        >
                            <template #cell(invoice_date)="data">
                                {{ data.item.InvoiceDate | date }}
                            </template>

                            <template #cell(type)="data">
                                <p>
                                    <span class="d-block font-weight-bolder">{{ getCategoryName(data.item.Category) }}</span>
                                    <span class="d-block font-small-4">{{ data.item.InvoiceNumber}}</span>
                                </p>
                                <p class="font-small-3 mb-0">{{ truncateStr(data.item.InternalComments, 12) }}</p>
                            </template>

                            <template #cell(total_amount)="data">
                                <span :class="data.item.Type == 1 ? 'text-success' : 'text-danger'">
                                    {{data.item.Type == 1 ? '+' : '-'}}{{ {amount: data.item.TotalAmount, currency: data.item.Currency } | currency }}
                                </span>
                            </template>

                            <template #cell(vendor)="data">
                                <span v-if="data.item.VendorObj">{{ data.item.VendorObj.Label }}</span>
                            </template>

                            <template #cell(PaidStatus)="data">
                                <b-badge
                                    :variant="statusLabelClass(data.item.PaidStatus)"
                                    class="mr-1 badge-pill">
                                    {{statusLabel(data.item.PaidStatus)}}
                                </b-badge>
                            </template>

                        </b-table>

                        <b-row>
                            <b-col
                                cols="12"
                                sm="6"
                                class="
                                    d-flex
                                    align-items-center
                                    justify-content-center justify-content-sm-start
                                  "
                            >

                                <span class="text-muted">{{ i18nT(`Showing`) }}
                            {{ dataMeta.from }} {{ i18nT(`to`) }}
                            {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                            {{ i18nT(`entries`) }}</span>
                            </b-col>
                            <!-- Pagination -->
                            <b-col
                                cols="12"
                                sm="6"
                                class="
                                d-flex
                                align-items-center
                                justify-content-center justify-content-sm-end
                              "
                            >
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalDocuments"
                                    :per-page="perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6">
                        <transition-group name="fade" mode="out-in">
                            <div v-if="!selectedRow.Id" :key="`no-row`" class="d-flex justify-content-center align-items-center h-100">
                                <div class="text-center pt-2" >
                                    <h4>{{i18nT(`Select a document`)}}</h4>
                                    <p>{{i18nT(`Pick an item from the list on the left to link the transaction as a payment record`)}}</p>
                                </div>
                            </div>

                            <div v-else :key="`has-row`">
                                <h4 class="mb-2">{{i18nT(`Select or create new payment record`)}}</h4>
                                <transition-group name="fade" mode="out-in">
                                    <div v-if="availablePayments.length > 0" :key="'table'">
                                        <b-table
                                            id="paymentsListTable"
                                            :items="availablePayments"
                                            :fields="paymentItemsFields"

                                        >
                                            <template #cell(checkbox)="data">
                                                <b-form-radio
                                                    v-model="selectedPreviousPayment"
                                                    :value="data.item.Id"
                                                    name="previousPayment"
                                                ></b-form-radio>
                                            </template>

                                            <template #cell(CreatedAt)="data">
                                                <div v-if="data.item.IsNew">
                                                    <span>{{ data.item.CreatedAt | date }}</span>
                                                    <b-badge  variant="primary" class="badge-pill ml-1">
                                                        {{i18nT(`Add New`)}}
                                                    </b-badge>
                                                </div>

                                                <div v-else>
                                                    <span>{{ data.item.CreatedAt | date }}</span>
                                                    <b-badge variant="secondary" class="badge-pill ml-1">
                                                        {{i18nT(`Existing`)}}
                                                    </b-badge>
                                                </div>
                                            </template>

                                            <template #cell(Amount)="data">
                                                <span>{{ {amount: data.item.Amount, currency: data.item.Currency, locale: curLocale} | currency }}</span>
                                            </template>

                                            <template #cell(PaymentType)="data">
                                                <font-awesome-icon
                                                    :icon="['fas', paymentTypeIcon(data.item.PaymentType)]"
                                                    class="mr-1"
                                                />
                                                <span v-if="PaymentTypeDict && PaymentTypeDict[data.item.PaymentType]"
                                                >{{ PaymentTypeDict[data.item.PaymentType] }}</span>
                                            </template>

                                            <template #cell(Description)="data">
                                                <span>{{ data.item.Description | truncate(50) }}</span>
                                            </template>


                                        </b-table>
                                    </div>
                                    <div class="text-center pt-2" v-else v-html="emptyHtmlPayments" :key="`no-payments`">
                                    </div>
                                </transition-group>

                                <div v-if="!hasNewRecord && selectedRow.Id" id="add-new-container" class="d-flex align-items-center justify-content-center">
                                    <b-button variant="outline-primary" @click="addNewRecord()">
                                        <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />
                                        {{i18nT(`Add as new payment`)}}
                                    </b-button>
                                </div>
                            </div>
                        </transition-group>




                    </b-col>
                </b-row>


            </validation-observer>


            <!-- Modal Footer -->
            <template #modal-footer>
                <div class="d-flex justify-content-end">
                    <b-button
                        variant="outline-secondary"
                        class="mr-2"
                        right
                        @click="cancel"
                    >
                        {{i18nT(`Cancel`)}}
                    </b-button>

                    <b-button
                        variant="primary"
                        right
                        @click="linkToPayment()"
                        :disabled="!canSave"
                    >
                        {{i18nT(`Save`)}}
                    </b-button>
                </div>
            </template>
        </b-modal>

    </div>


</template>

<script>

import {
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BTable,
    BBadge,
    VBTooltip,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadio, BPagination, BFormCheckbox
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
// import {required, email} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from "@/auth/useAuth";
import useAbrigedFinanceList from "@/views/accounting/Transactions/useAbrigedFinanceList"
import {avatarText} from "@core/utils/filter";
import {dictToSelectArray} from "@core/utils/utils";
import vSelect from "vue-select";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import moment from "moment";

export default {
    name: "TransactionToFinance",
    components: {
        BFormCheckbox, BPagination,
        BFormGroup,
        BCard,
        BRow,
        BCol,
        BButton,
        BModal,
        BTable,
        BBadge,
        BFormInput,

        BInputGroup,
        BInputGroupPrepend,
        BFormRadio,
        vSelect,
        ValidationObserver,
        VueCtkDateTimePicker
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    props: {
        transaction: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            curLocale: this.$i18n.locale,

            selectedItems: [],
            selectedPreviousPayment: null,
            currentItems: [],

            selectedRow: {},
            availablePayments: [],
            PaymentTypeDict: [],
            selectedRowIndex: null,

            vendorList: [],
            paymentCategoryList: [],

            paymentItemsFields: [
                { key: 'checkbox', label: this.i18nT(``), thClass: 'tbl-chk', tdClass: 'tbl-chk' },
                { key: 'CreatedAt', label: this.i18nT(`Date`), sortable: true },
                { key: 'Amount', label: this.i18nT(`Amount`), sortable: true },
                { key: 'PaymentType', label: this.i18nT(`Via`), sortable: true }, //
                { key: 'Description', label: this.i18nT(`Description`), sortable: true },
            ],

            reportRange: {
              start: '',
              end: ''
            },

            vatPercent: 0,
            invoiceNumber: '',
            lastInvoiceNumber: '',

            saveAndEdit: false,
            dontRemind: false,
        }
    },
    computed: {
        hasNewRecord(){
            return this.availablePayments.find(item => item.IsNew);
        },
        canSave(){
            return this.selectedItems.length ? true : false;
        },
        dateFormat() {
            return useAuth.getDateFormat() !== 'undefined'
                ? useAuth.getDateFormat()
                : 'DD/MM/YYYY'
        },
        localeCtk() {
            return useAuth.getLocale() !== 'undefined'
                ? useAuth.getLocale()
                : 'en'
        },
        minDate() {
            return moment('2022-01-01').format();
        },
        documentType() {
            return this.transaction.Amount > 0 ? 1 : 2;
        },
        emptyHtml() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No matching records found`) + "</h4>";
        },
        emptyHtmlPayments() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No matching records found`) + "</h4>";
        },
    },
    methods: {
        resetState(){
          this.selectedRow = {};
          this.selectedItems = [];
        },
        paymentTypeIcon(type) {
            switch (type) {
                case 'cash':
                    return 'money-bill-alt'
                case 'sepa_debit':
                    return 'piggy-bank'
                case 'card':
                    return 'credit-card'
                case 'bank':
                    return 'university'
                default:
                    return 'money-bill-alt'
            }
        },
        getCategoryName(typeId) {
            const invoiceType = this.paymentCategoryList.find(type => type.value == typeId)
            return invoiceType ? invoiceType.text : ''
        },
        statusLabelClass(status) {
            return {
                'paid': 'success',
                'partial': 'warning',
                'not_paid': 'light-dark',
                'overdue': 'danger',
                'overpaid': 'info',
                'void': 'dark'
            }[status]
        },
        statusLabel(status){
            return {
                'paid': this.i18nT(`Paid`),
                'partial': this.i18nT(`Partial`),
                'not_paid': this.i18nT(`Not paid`),
                'void': this.i18nT(`Void`),
                'overpaid': this.i18nT(`Overpaid`),
                'overdue': this.i18nT(`Overdue`)
            }[status]
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        rowClass(item) {
            if(item){
                return item.activeRow ? 'active-row' : ''
            }else {
                return '';
            }

        },
        onRowClicked(item, index) {
            let self = this;
            this.documents.forEach((item, itemIndex) => {
                self.documents[itemIndex].activeRow = false;
            });
            item.activeRow = true;
            this.selectedRow = item;
            this.selectedItems = [];
            this.availablePayments = [...item.InvoicePayments];
            this.availablePayments.push({
                Id: `0`,
                Amount: this.transaction.Amount,
                CreatedAt:this.transaction.TransactionDate,
                IsNew: true,
                Currency: this.transaction.Currency,
                PaymentType: 'bank',
                Description: this.transaction.Label
            });
            this.selectedRowIndex = index;
            this.selectedPreviousPayment = `0`;
            this.selectedItems = [`0`];
        },
        addNewRecord(){
        },
        cancel() {
            this.resetForm();
            this.$refs.createInvoice.reset();
            this.$bvModal.hide('modal-link-transaction')
        },
        resetForm() {
            this.vendorId = null;
            this.paymentCategory = 0;
            this.paymentCategoryId = null;
            this.vatPercent = 0;
            this.invoiceNumber = '';
        },
        postData(){
            let self= this;

            let formData = new FormData();
            formData.append('transaction_id', this.transaction.Id);
            formData.append('invoice_id', this.selectedRow.Id);
            if(this.selectedItems.length > 0){
              formData.append('payment_id', this.selectedItems[0]);
            }

            this.$http.post(`transactions/createPaymentForInvoice`, formData).then((response) => {
              self.$emit('ready');

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.successMessage(response),
                  icon: 'CheckIcon',
                  variant: 'success',
                  message: this.i18nT(`Transaction successfully linked to payment!`)
                },
              });

              this.resetForm();

              this.$bvModal.hide('modal-link-transaction');


            }).catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.errorMessage(error),
                  icon: 'XIcon',
                  variant: 'danger',
                  message: this.i18nT(`Something went wrong!`)
                },
              });
            })

        },
        linkToPayment() {

          if(this.dontRemind){
            this.postData()
          } else {
            this.$swal({
              title: this.i18nT(`Disclaimer`),
              icon: 'warning',
              html: `
              <p>${this.i18nT(`You won't be able to delete an invoice after recording a payment!`)}</p>
              <div class="py-1">
              <input type="checkbox" id='dontRemind-transaction' class="sk-dontCheckbox" value="1" />
              <label for="dontRemind" class="ml-05">${this.i18nT(`Don't remind me again`)}</label>
              </div>`
              ,
              showCancelButton: true,
              confirmButtonText: this.i18nT(`Record!`),
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1'
              },
              buttonsStyling: false
            })
                .then(result => {
                  if (result.value) {
                    if (document.getElementById('dontRemind-transaction').checked) {
                      this.dontRemind = document.getElementById('dontRemind-transaction').checked;
                      useAuth.setDontRemind({name: 'transaction', value: this.dontRemind})
                    }
                    this.postData()
                  }
                })
          }
        },

    },
    setup() {
        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            invoiceType,
            category,
            assignedTo,
            vendor,
            paymentType,
            documents,
            documentFilter,
            startDate,
            endDate,
            refetchData
        } = useAbrigedFinanceList(true)

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            invoiceType,
            category,
            assignedTo,
            vendor,
            paymentType,
            startDate,
            endDate,
            avatarText,
            documents,
            documentFilter,
            refetchData
        }
    },
    created() {

        this.dontRemind = useAuth.getDontRemind('transaction');

        this.$http.get(`system/invoicePaymentTypes`).then((resp) => {
            this.PaymentTypeDict = resp.data.data;
        });
        this.$http.get(`invoices/categories`).then(({data}) => {
            this.paymentCategoryList = data.data.map(category => {
                return {
                    value: category.Id,
                    text: category.Label,
                    fullData: category
                }
            })
        })

        let filterString = '';

        if(this.documentType == 1){
            filterString = '?invoicing=1';
        }

        this.invoiceType = { value: this.documentType, text: '' } ;

        this.$http.get(`vendors/vendorsList`+filterString).then(({data}) => {
            this.vendorList = dictToSelectArray(data.data)
            this.vendorList.unshift({
                value: -1,
                text: this.i18nT(`All`)
            })
        })

        let curWorkspace = useAuth.getCurWorkspace();
        this.vatPercent = curWorkspace.MainVatPercent;
    },
    watch: {
        transaction: {
          handler: function(val) {
            if (val && val.TransactionDate) {
              this.reportRange.start = '';
              this.reportRange.end = moment(val.TransactionDate).format('YYYY-MM-DD')
            }
          },
          deep:true
        },
        selectedPreviousPayment(val){
            this.selectedItems = [val];
        },
        documentType(val){
            this.invoiceType = { value: val, text: '' } ;
        },
        documents(){
            this.availablePayments = [];
            this.selectedRow = {};
            this.selectedItems = [];
        },
        reportRange: {
            handler: function(newRange) {
              if (newRange) {
                if (newRange.start) {
                  this.startDate = moment(newRange.start).format('YYYY-MM-DD');
                }

                if (newRange.end) {

                }
              } else {
                this.startDate = '';
                this.endDate = '';
              }
            },
          deep: true,

        },
    }
}
</script>

<style>

div.date-time-picker {
    width: calc(100% - 56px);
}
.date-time-picker input.field-input {
    padding-right: 5px;
}

.ctk-date-time-picker__header,
.datetimepicker .custom-button,
.datetimepicker span.custom-button-content,
.datepicker-buttons-container .datepicker-button.now .datepicker-button-content
{
    /* Change the header text color */
    color: #5a5aff !important;
    fill: #5a5aff !important;
}


#modal-link-transaction___BV_modal_content_ div.table-responsive {
    min-height: 200px;
}


#invoiceListTable.table th, #invoiceListTable.table td,
#paymentsListTable.table th, #paymentsListTable.table td{
    padding: 0.5em;
    font-size: 0.9em;
}

#invoiceListTable tr {
    transition: all 0.3s ease;
}

#invoiceListTable tr:hover {
    cursor: pointer;
    background-color: rgba(90, 90, 255, 0.1);
}

#invoiceListTable tr.active-row {
    background-color: rgba(90, 90, 255, 0.1);
}

.sk-dontCheckbox{
  position: relative;
  top: 2px;
}
</style>


<style scoped lang="scss">


.max-w-200 {
    max-width: 200px;
}
.plain-modal {
    background-color: var(--light-gray);
}

.discountIcon {
    position: relative;
    margin: 0 auto;
    margin-top: calc(50% - 1px);
}

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}
</style>
